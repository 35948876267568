import React from 'react'
import styles from './Update.module.css'
import {routes} from '../../constants/consts-routes'

export default function Update(props) {
  const {
    APP_VERSION,
    version,
  } = props

  return (
    <section className={styles.Update}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-4 col-lg-3"/>
          <div className="col-xs-12 col-sm-6 col-lg-5">
            <p>Version: {APP_VERSION} > {version}</p>
            <p>
              <a
                href={routes.homepage.pathname}
                className="button"
              >
                Refresh App
              </a>
            </p>

            <small>If you continue to see this message, please clear your browser-cache and reload page.</small>
            <dl>
              <dt>Mac</dt>
              <dd>Shift Command R</dd>
            </dl>
            <dl>
              <dt>PC</dt>
              <dd>CTRL F5</dd>
            </dl>
          </div>
        </div>
      </div>
    </section>
  )
}
