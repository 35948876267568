import {LIB} from './consts-dictionary'

export const postfix = ' · JA JA'

export const routes = Object.freeze({
  homepage: {
    pathname: '/',
    title: `${LIB.welcome}${postfix}`,
    header: `${LIB.welcome}`,
  },
  update: {
    pathname: '/update/',
    title: `${LIB.update}${postfix}`,
    header: `${LIB.update}`,
  },
  login: {
    pathname: '/login/',
    title: `${LIB.log_in}${postfix}`,
    header: `${LIB.log_in}`,
  },
  forgot: {
    pathname: '/forgot/',
    title: `${LIB.forgot_password}${postfix}`,
    header: `${LIB.forgot_password}`,
  },
  signup: {
    pathname: '/signup/',
    title: `${LIB.sign_up}${postfix}`,
    header: `${LIB.sign_up}`,
  },
  terms_and_conditions: {
    pathname: '/terms-and-conditions/',
    title: `${LIB.terms_and_conditions}${postfix}`,
    header: `${LIB.terms_and_conditions}`,
  },
  styleguide: {
    pathname: '/styleguide/',
    title: `Styleguide${postfix}`,
    header: `Styleguide${postfix}`,
  }
})
