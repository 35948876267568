import React from 'react'
import styles from './Home.module.css'
import Halo from "../assets/images/Cover_Halo.jpg";
import Boy from "../assets/images/Cover_Boy.jpg";
import Logo from "../assets/images/jaja.svg"
import Heart from "../assets/images/heart.svg"
import Svg from "../components/Svg/Svg"
import ICONS from "../constants/consts-icontypes"

export default function Home() {
  return (
    <>
      <img className={styles.videoPoster} src="/assets/video/BOY---8-sec-visuals_poster.jpg" alt=""/>
      <video
        autoPlay
        playsInline
        muted
        loop
        preload="auto"
        className={styles.video}
      >
        <source
          src="/assets/video/BOY-visuals.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      <section className={styles.Home}>

        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className={styles.logo}>
                <img src={Logo} alt="JA JA"/>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className={styles.header}>

                <span>NEW SINGLE OUT!</span>
                <h1 style={{margin: '0'}}><strong>BOY</strong></h1>
                <a
                  style={{fontSize: '2rem', padding: '1rem 0 0 0', display: 'block'}}
                  href="https://jaja.lnk.to/BOY"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LISTEN HERE&nbsp;&nbsp;<Svg id={ICONS.arrowRight} style={{transform: 'scale(2) translateY(-2px)'}}/>
                </a>
                <br/>
                <br/>
                <br/>
                <h1><small><strong>JA JA</strong></small></h1>
                <p><small>Copenhagen based rock trio JA JA released their debut single DARK HALO (2022) where hard rock meets dark pop in a state of destructive love and emotional addiction. The follow-up BOY (2022) is a throwback to a lost childhood with broken dreams in rural Nordics.<br/>Founded in 2021.</small>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className={styles.releases}>
                <h2>SINGLES</h2>
                <div className={styles.covers}>

                  <div className={styles.cover}>
                    <small>OUT JULY 8</small>
                    <h2><strong>BOY</strong></h2>
                    <a href="https://jaja.lnk.to/BOY" target="_blank" rel="noopener noreferrer">
                      LISTEN NOW&nbsp;<Svg id={ICONS.arrowRight}/>
                    </a>
                    <img src={Boy} alt="Boy"/>
                  </div>

                  <div className={styles.cover}>
                    <small>RELEASED MAY 6</small>
                    <h2><strong>DARK HALO</strong></h2>
                    <a href="https://jaja.lnk.to/DARK" target="_blank" rel="noopener noreferrer">
                      LISTEN NOW&nbsp;<Svg id={ICONS.arrowRight}/>
                    </a>
                    <img src={Halo} alt="Dark Halo"/>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      <img src={Heart} alt="heart" className={styles.heart}/>
    </>
  )
}
