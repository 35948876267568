import React, {Component} from 'react'
import ICONS from '../constants/consts-icontypes'
import Svg from '../components/Svg/Svg'
import styles from './Home.module.css'
import {routes} from "../constants/consts-routes"

class Home extends Component {
  render() {
    return (
        <section className={styles.Home}>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-5">
                <h1>Typography</h1>
              </div>
              <div className="col-xs-12 col-md-offset-1 col-md-6">
                <h1>Header 1</h1>
                <h2>Header 2</h2>
                <h3>Header 3</h3>
                <p>Paragraph. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog.</p>
                <p>Paragraph. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog.</p>
                <p><strong>Strong</strong></p>
                <ul>
                  <li>List item</li>
                  <li>List item
                    <ul>
                      <li>List item</li>
                      <li>List item</li>
                      <li>List item</li>
                    </ul>
                  </li>
                  <li>List item</li>
                </ul>
                <ol>
                  <li>List item</li>
                  <li>List item
                    <ol>
                      <li>List item</li>
                      <li>List item</li>
                      <li>List item</li>
                    </ol>
                  </li>
                  <li>List item</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <hr className="xl"/>
              </div>
              <div className="col-xs-12 col-md-5">
                <h1>Buttons</h1>
              </div>
              <div className="col-xs-12 col-md-offset-1 col-md-6">
                <div className="button-group">
                  <button className="button">Button primary</button>
                  <button className="button" disabled>Button primary disabled</button>
                </div>
                <div className="button-group">
                  <button className="button outline">Button outline</button>
                  <button className="button outline" disabled>Button outline disabled</button>
                </div>
                <div className="button-group">
                  <a href={routes.styleguide.pathname} className="button">Button link primary</a>
                  <a href={routes.styleguide.pathname} className="button outline">Button link outline</a>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <hr className="xl"/>
              </div>
              <div className="col-xs-12 col-md-5">
                <h1>Forms</h1>
              </div>
              <div className="col-xs-12 col-md-offset-1 col-md-6">
                <form>
                  <label htmlFor="my-input">Label</label>
                  <div className="input">
                    <input id="my-input" type="text" placeholder="Input text"/>
                  </div>
                  <label htmlFor="my-textarea">Label</label>
                  <div className="input">
                    <textarea id="my-textarea" defaultValue="Textarea. The quick brown fox jumps over the lazy dog"/>
                  </div>

                  <label className="checkbox">
                    <input type="checkbox"/>
                    <span className="checklabel">The quick brown fox jumps over the <a href="https://google.com" target="_blank" rel="noreferrer">lazy dog</a>. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog.</span>
                    <span className="checkmark"/>
                  </label>

                  <label className="checkbox">
                    <input type="checkbox"/>
                    <span className="checklabel">Check label</span>
                    <span className="checkmark"/>
                  </label>

                  <label className="radio">
                    <input type="radio" name="gender" value="female"/>
                    <span className="checklabel">Female</span>
                    <span className="checkmark"/>
                  </label>
                  <label className="radio">
                    <input type="radio" name="gender" value="male"/>
                    <span className="checklabel">Male</span>
                    <span className="checkmark"/>
                  </label>

                  <button className="button">Button</button>

                </form>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <hr className="xl"/>
              </div>
              <div className="col-xs-12 col-md-5">
                <h1>Icons</h1>
              </div>
              <div className="col-xs-12 col-md-offset-1 col-md-6">
                <div className="row">
                  {
                    Object.keys(ICONS).map((icon, idx) => {
                      return (
                        <div key={idx} className="col-xs-2" style={{minHeight: '100px', display: 'flex', flexDirection: 'column'}}>
                          <Svg id={ICONS[icon]}/>
                          <label style={{marginTop: '1rem', fontSize: '11px', opacity: '.5'}}>{ICONS[icon]}</label>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
    )
  }
}

export default Home
