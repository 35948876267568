import React, {useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import styles from './Signup.module.css'
import {routes} from '../../constants/consts-routes'
import Svg from '../../components/Svg/Svg'
import ICONS from '../../constants/consts-icontypes'
import {createUser, sendUserEmailVerification} from '../../services/service-auth'
import {LIB} from '../../constants/consts-dictionary'
import {PATTERN_EMAIL, PATTERN_USERNAME, REGEX_USERNAME} from '../../constants/consts-patterns'
import {addDocument, getCollectionWhere} from '../../services/service-firestore'
import {slugify} from '../../services/service-utils'

export default function Signup(props) {
  const form = useRef()
  const navigate = useNavigate()

  const [error, setError] = useState(null)

  const [usernameValid, setUsernameValid] = useState(false)
  const [usernameErrorMessage, setUsernameErrorMessage] = useState(null)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [slug, setSlug] = useState('')
  const [letter, setLetter] = useState(false)
  const [capital, setCapital] = useState(false)
  const [number, setNumber] = useState(false)
  const [length, setLength] = useState(false)

  function onUserNameChange(event) {
    setUsername(event.target.value)
    checkUserName()
  }

  async function checkUserName() {
    const inputUserName = form.current.elements.namedItem('name')

    if(!username) {
      setUsernameErrorMessage('')
    }

    const slug = slugify(username)

    const valid = Boolean(username && REGEX_USERNAME.test(username))
    console.log('valid', valid)
    setUsernameValid(valid)
    setSlug(slug)


    if(!valid) {
      inputUserName.setCustomValidity(LIB.error_username)
      setUsernameErrorMessage(LIB.error_username)

      setTimeout(() => {
        inputUserName.focus()
      }, 250)
    } else {
      inputUserName.setCustomValidity('')
      const {validity} = inputUserName
      console.log('validity', validity)
      setUsernameErrorMessage('')
      console.log('check if username is unique')
      const response = await getCollectionWhere('admins', 'username_lc', username.toLowerCase())
      const userNameExists = response && response.length > 0
      console.log('userNameExists', userNameExists)
      if(userNameExists) {
        inputUserName.setCustomValidity(LIB.error_username_unique)
        setUsernameErrorMessage(LIB.error_username_unique)
      } else {
        inputUserName.setCustomValidity('')
        setUsernameErrorMessage('')
      }
    }
  }

  function onEmailChange(event) {
    setEmail(event.target.value)
  }

  async function onSubmit(event) {
    setError(null)

    const valid = form.current.checkValidity()

    if(valid) {
      event.preventDefault()

      const email = form.current.elements.namedItem('email').value
      const password = form.current.elements.namedItem('password').value

      const response = await createUser(email, password)
      console.log('response', response)

      if(response && response.code) {
        setError(response.message)
      } else {
        console.log('user created', response)
        createAdmin()
      }
    } else {
      hintPasswordValidation()
    }
  }

  async function createAdmin() {
    const {user} = props
    if(!user) {
      console.log('Cannot create admin (no user)')
      return
    }

    const {uid, email} = user

    const data = {
      created: new Date().toISOString(),
      uid: uid,
      email: email,
      username: username,
      slug: slug,
    }
    console.log('createAdmin with', data)

    const response = await addDocument('admins', data)
    console.log('response', response)
    if(response && response.error) {
      setError(`${response.message} [${response.error}]`)
    } else {
      console.log('admin created', response)
      console.log('sending email verification...')

      const responseVerificationEmail = await sendUserEmailVerification()
      if(responseVerificationEmail) {
        console.log('sendEmailVerification success')
        navigate(`${routes.homepage.pathname}${slug}`)
      } else {
        console.log('sendEmailVerification error')
      }
    }
  }

  function hintPasswordValidation() {
    const inputPassword = form.current.elements.namedItem('password')

    const lowerCaseLetters = /[a-z]/g
    if(inputPassword.value.match(lowerCaseLetters)) {
      setLetter(false)
    } else {
      setLetter(true)
    }

    const upperCaseLetters = /[A-Z]/g
    if(inputPassword.value.match(upperCaseLetters)) {
      setCapital(false)
    } else {
      setCapital(true)
    }

    // Validate numbers
    const numbers = /[0-9]/g
    if(inputPassword.value.match(numbers)) {
      this.setState({number: false})
      setNumber(false)
    } else {
      setNumber(true)
    }

    // Validate length
    if(inputPassword.value.length >= 8) {
      setLength(false)
    } else {
      setLength(true)
    }
  }

  return (
    <section className={styles.Signup}>
      <div className="container">
        <div className="row center-xs">
          <div className="col-xs-12 col-sm-6 col-lg-5 col-xl-4 col-xxl-3">

            <form
              ref={form}
              autoComplete="off"
            >
              <div className="input-wrapper">
                <label htmlFor="name">{LIB.enter_username}</label>
                <div className="input">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={username}
                    onChange={onUserNameChange}
                    placeholder={LIB.placeholder_username}
                    pattern={PATTERN_USERNAME}
                    title={LIB.error_username}
                    autoComplete="off"
                    required
                  />
                  {usernameValid && !usernameErrorMessage &&
                    <small className={styles.usernameValid}>
                      <Svg id={ICONS.checkmark}/>
                    </small>
                  }
                </div>
                {usernameErrorMessage &&
                  <small className={styles.usernameInValid}>{usernameErrorMessage}</small>
                }

                {slug &&
                  <small className={styles.slug}>/{slug}</small>
                }
              </div>
              <div className="input-wrapper">
                <label htmlFor="email">{LIB.enter_your_email}</label>
                <div className="input">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={onEmailChange}
                    placeholder={LIB.placeholder_your_email}
                    pattern={PATTERN_EMAIL}
                    title={LIB.error_email}
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <label htmlFor="password">{LIB.enter_a_password}</label>
                <div className="input">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="******"
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    title={LIB.error_password}
                    autoComplete="off"
                    required
                  />
                </div>
                {(letter || capital || number || length) &&
                  <div className="error-message">
                    <h4>Password must contain the following:</h4>
                    {letter && <small id="letter" className="invalid">A <b>lowercase</b> letter</small>}
                    {capital && <small id="capital" className="invalid">A <b>capital (uppercase)</b> letter</small>}
                    {number && <small id="number" className="invalid">A <b>number</b></small>}
                    {length && <small id="length" className="invalid">Minimum <b>8 characters</b></small>}
                  </div>
                }
              </div>

              {error &&
                <div className="error-message">{error}</div>
              }

              <button
                type="submit"
                onClick={onSubmit}
              >
                {LIB.sign_up}
              </button>
            </form>

            <small className={styles.signupWrapper}>
              <span>{LIB.already_have_account}</span>
              <Link to={routes.login.pathname}>
                <span><strong>{LIB.log_in}</strong></span>
                <Svg id={ICONS.arrowRightSmall}/>
              </Link>
            </small>

          </div>
        </div>
      </div>
    </section>
  )
}
