const ICONS = Object.freeze({
  arrowRight: 'arrow-right',
  arrowDown: 'arrow-down',
  arrowRightSmall: 'arrow-right-small',
  arrowDownSmall: 'arrow-down-small',
  checkmark: 'checkmark',
  checkmarkLarge: 'checkmark-large',
  plusMedium: 'plus-medium',
})

export default ICONS
