import React from 'react'
import styles from './Svg.module.css'

function Svg(props) {
  const {className, id} = props
  return (
    <svg {...props} className={`${styles[id]} ${className ? className : ''}`}>
      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#svg-${props.id}`}/>
    </svg>
  )
}

export default Svg
