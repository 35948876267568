const isDevEnv = Boolean(window.location.host.includes('localhost'))

export const analyticsPageView = (pageTitle, pathname) => {
  console.log('analyticsPageView', {pageTitle, pathname})
  if(isDevEnv) return
  window.gtag('config', window.GA_TRACKING_ID, {
    'page_title': pageTitle,
    'page_path': pathname
  })
}

export const analyticsEvent = (category = null, action = null, label = null, value = null) => {
  console.log('analyticsEvent', {category, action, label, value})
  if(isDevEnv) return
  window.gtag('event', action,
    {
      'event_category': category,
      'event_label': label,
      'value': value
    }
  )
}
