import React, {useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import styles from './Login.module.css'
import {routes} from '../../constants/consts-routes'
import {signIn} from '../../services/service-auth'
import {LIB} from '../../constants/consts-dictionary'
import {PATTERN_EMAIL} from '../../constants/consts-patterns'
import ICONS from '../../constants/consts-icontypes'
import Svg from '../../components/Svg/Svg'

export default function Login() {
  const form = useRef()
  const navigate = useNavigate()
  const [error, setError] = useState(null)

  async function onSubmit(event) {
    setError(null)

    const valid = form.current.checkValidity()
    console.log('valid', valid, form.current.elements.namedItem('password').value)

    if(valid) {
      event.preventDefault()

      const email = form.current.elements.namedItem('email').value
      const password = form.current.elements.namedItem('password').value

      const response = await signIn(email, password)
      console.log('response', response)

      if(response && response.code) {
        setError(response.message)
      } else {
        navigate(routes.homepage.pathname)
      }
    }
  }

  return (
    <section className={styles.Login}>
      <div className="container">
        <div className="row center-xs">
          <div className="col-xs-12 col-sm-6 col-lg-5 col-xl-4 col-xxl-3">

            <form ref={form}>
              <div className="input-wrapper">
                <label htmlFor="email">{LIB.enter_your_email}</label>
                <div className="input">
                  <input
                    id="email"
                    type="email"
                    placeholder={LIB.placeholder_your_email}
                    pattern={PATTERN_EMAIL}
                    title={LIB.error_email}
                    required
                    tabIndex={1}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className={styles.labels}>
                  <label htmlFor="password">{LIB.enter_a_password}</label>
                  <Link
                    to={routes.forgot.pathname}
                    tabIndex={4}
                  >
                    {LIB.forgot_password}
                  </Link>
                </div>
                <div className="input">
                  <input
                    id="password"
                    type="password"
                    placeholder="******"
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    title={LIB.error_password}
                    required
                    tabIndex={2}
                  />
                </div>
              </div>

              {error &&
                <div className="error-message">{error}</div>
              }

              <button
                type="submit"
                onClick={onSubmit}
                tabIndex={3}
              >
                {LIB.log_in}
              </button>
            </form>

            <small className={styles.signupWrapper}>
              <span>{LIB.no_account_yet}</span>
              <Link to={routes.signup.pathname}>
                <span><strong>{LIB.sign_up}</strong></span>
                <Svg id={ICONS.arrowRightSmall}/>
              </Link>
            </small>

          </div>
        </div>
      </div>
    </section>
  )
}
