import {auth} from './service-firebase'
import {EmailAuthProvider, createUserWithEmailAndPassword, deleteUser, onAuthStateChanged, reauthenticateWithCredential, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateEmail, sendEmailVerification} from 'firebase/auth'

export const observeUser = (onStateChange) => {
  onAuthStateChanged(auth, user => {
    onStateChange(user)
  })
}

export const createUser = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      console.log('createUser success', userCredential)
      return userCredential.user
    })
    .catch(error => {
      const {code, message} = error
      console.log('createUser error', code, message)
      return error
    })
}

export const sendUserEmailVerification = () => {
  return sendEmailVerification(auth.currentUser)
    .then(() => {
      return true
    })
    .catch(error => {
      const {code, message} = error
      console.log('sendUserEmailVerification error', code, message)
      return error
    })
}

export const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      console.log('signIn success', userCredential)
      return userCredential.user
    })
    .catch(error => {
      const {code, message} = error
      console.log('signIn error', code, message)
      return error
    })
}

export const resetPassword = (email) => {
  return sendPasswordResetEmail(auth, email)
    .then(() => {
      console.log('resetPassword success')
      return true
    })
    .catch((error) => {
      const {code, message} = error
      console.log('resetPassword error', code, message)
      return error
    })
}

export const signUserOut = () => {
  return signOut(auth)
    .then(() => {
      console.log('signOut success')
      return true
    })
    .catch((error) => {
      const {code, message} = error
      console.log('signOut error', code, message)
      return error
    })
}


export const reauthenticateUser = (password) => {
  const user = auth.currentUser
  const {email} = user
  console.log('reauthenticateUser > cred', email, password)
  if(!password) {
    return {
      error: 'password missing',
      message: 'Please fill in your current password'
    }
  }

  const credential = EmailAuthProvider.credential(
    email,
    password
  )

  return reauthenticateWithCredential(user, credential)
    .then(() => {
      console.log('reauthenticateUser success')
      return true
    })
    .catch(error => {
      const {code, message} = error
      console.error('reauthenticateUser error', code, message)
      return error
    })
}


export const updateUserEmail = async(email, password) => {
  const reAuth = await reauthenticateUser(password)
  if(reAuth && reAuth.code) {
    return reAuth
  }

  if(email) {
    return updateEmail(auth.currentUser, email)
      .then(() => {
        console.log('updateEmail success')
        return true
      })
      .catch(error => {
        const {code, message} = error
        console.error('updateEmail error', code, message)
        return error
      })
  }
}

export const deleteCurrentUser = () => {
  const user = auth.currentUser
  if(!user) return {code: 'No user', message: 'No user to delete'}

  return deleteUser(user)
    .then(() => {
      console.log('deleteUser success')
      return true
    })
    .catch((error) => {
      const {code, message} = error
      console.log('deleteUser error', code, message)
      return error
    })
}
