import React from 'react'
import {Routes, Route} from 'react-router-dom'
import styles from './Views.module.css'
import {routes} from '../../constants/consts-routes'
import Footer from "../Footer/Footer"
import Home from "../../pages/Home"
import Update from "../../pages/update/Update"
import Login from "../../pages/login/Login"
import Forgot from "../../pages/forgot/Forgot"
import Signup from "../../pages/signup/Signup"
import TermsConditions from "../../pages/terms_and_conditions/TermsConditions"
import Styleguide from "../../pages/Styleguide"

export default function Views() {
    return (
      <>
        <main  className={styles.Views}>
          <Routes>
            <Route
              exact
              path={routes.homepage.pathname}
              element={<Home/>}
            />
            <Route
              exact
              path={routes.update.pathname}
              element={<Update/>}
            />
            <Route
              exact
              path={routes.login.pathname}
              element={<Login/>}
            />
            <Route
              exact
              path={routes.forgot.pathname}
              element={<Forgot/>}
            />
            <Route
              exact
              path={routes.signup.pathname}
              element={<Signup/>}
            />
            <Route
              exact
              path={routes.terms_and_conditions.pathname}
              element={<TermsConditions/>}
            />
            <Route
              exact
              path={routes.styleguide.pathname}
              element={<Styleguide/>}
            />
          </Routes>
        </main>

        <Footer/>
      </>
    )
}
