export const isDevEnvironment = window.location.host === 'localhost:1508'

export const getViewPortWidth = () => {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
}
export const getViewPortHeight = () => {
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
}
export const isMobile = () => {
  return window.matchMedia("(max-width: 767px)").matches
}
export const isDesktop = () => {
  return window.matchMedia("(min-width: 1024px)").matches
}
export const isHD = () => {
  return window.matchMedia("(min-width: 1200px)").matches
}

export const scrollToId = (id, offsetY = 0) => {
  if(typeof document === "undefined") return
  if(id) {
    const element = document.getElementById(id)
    if(element) scrollToElement(element, offsetY)
  }
}

export const scrollToElement = (element, offsetY = 0) => {
  if(element) {
    const offset = element.getBoundingClientRect()
    // console.log('element', window.scrollY)
    window.scrollTo({
      top: offset.top + window.scrollY + offsetY,
      behavior: "smooth"
    })
  }
}

export const getLanguageCode = () => {
  const langCode = document.querySelector('html').getAttribute('lang')
  // console.log('langCode', langCode)
  return langCode
}

export const setLanguageCode = (language_code) => {
  // console.log('language_code', language_code)
  const languageCode = language_code ? language_code : navigator.language
  // console.log('languageCode', languageCode)
  document.querySelector('html').setAttribute('lang', languageCode)
}

export const slugify = (string) => {
  if(!string) return string
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '') // Replace spaces with ''
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '') // Replace multiple - with single -
    .trim()
}

export const getUrlSegment = (segmentIndex) => {
  const {location} = window
  return `${location.pathname.split('/')[segmentIndex]}`
}

export const getRoute = pathname => `/${pathname.split('/')[1]}`

export const emailIsValid = email => {
  // RFC 2822:
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const getSearchIndexFromString = str => {
  if(typeof str !== 'string' || !str) return null

  const lowerCase = str.toLowerCase()

  let searchIndex = []
  const reducer = (accumulator, currentValue) => {
    searchIndex.push(accumulator)
    return accumulator + currentValue
  }

  lowerCase
    .split('')
    .reduce(reducer)
  searchIndex.push(lowerCase)

  const words = lowerCase.split(' ')
  if(words.length > 1) {
    lowerCase
      .split(' ')
      .forEach(word => {
        word
          .split('')
          .reduce(reducer)
        searchIndex.push(word)
      })
  }
  return [...new Set(searchIndex)]
}

export const getSearchIndexFromStringArray = strArray => strArray.map(str => getSearchIndexFromString(str)).flat()

let tempScrollPosition = 0
export const setLockedViewport = bool => {
  if(bool) {
    tempScrollPosition = document.documentElement.scrollTop
    document.body.style.position = 'fixed'
    document.body.style.height = '100vh'
    document.body.style.overflow = 'hidden'

  } else {
    document.body.style.position = ''
    document.body.style.height = ''
    document.body.style.overflow = ''
    document.documentElement.scrollTop = tempScrollPosition
  }
}
