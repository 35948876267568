import {db} from './service-firebase'
import {doc, addDoc, getDoc, onSnapshot, setDoc, getDocs, updateDoc, deleteDoc, collection, query, where, increment, arrayUnion, arrayRemove} from 'firebase/firestore'

const parseError = (serviceId, error) => {
  const {code, message} = error
  console.error(`${serviceId} Error`, code, message)
  const err = {
    error: code,
    message: message
  }
  console.log('parseError:', err)
  return err
}
export const parseField = (fsField) => {
  const collection = fsField.split('/').slice(0, -2).join('/')
  const doc = fsField.split('/').slice(-2)[0]
  const field = fsField.split('/').slice(-1)[0]
  return {
    collection,
    doc,
    field,
    fsField
  }
}
export const parseDoc = (fsDoc) => {
  const collection = fsDoc.split('/').slice(0, -1).join('/')
  const docId = fsDoc.split('/').slice(-1)[0]
  return {
    collection,
    docId
  }
}
const formatDoc = docSnap => {
  return {...{}, ...docSnap.data(), ...{id: docSnap.id}}
}
const formatCollection = collection => {
  return collection.docs.map((doc) => {
    return {...{}, ...doc.data(), ...{id: doc.id}}
  })
}

export const getCollection = async(path) => {
  const response = await getDocs(collection(db, path))
  // console.log('getCollection success:', formatCollection(response))
  return formatCollection(response)
}

export const getCollectionWhere = async(path, key, value) => {
  const q = query(collection(db, path), where(key, '==', value))
  const response = await getDocs(q)
  // console.log('getCollectionWhere success:', formatCollection(response))
  return formatCollection(response)
}

export const getCollectionWhereFieldMatchesAny = async(path, key, arrayValues) => {
  if(arrayValues && arrayValues.length > 10) {
    return parseError('getCollectionWhereFieldMatchesAny', {code: 'Error', message: 'WARNING: Max 10 items in arrayValues!'})
  }
  const q = query(collection(db, path), where(key, 'in', arrayValues))
  const response = await getDocs(q)
  // console.log('getCollectionWhereFieldMatchesAny success:', formatCollection(response))
  return formatCollection(response)
}

export const getCollectionWhereArrayContains = async(path, key, searchString) => {
  const q = query(collection(db, path), where(key, 'array-contains', searchString))
  const response = await getDocs(q)
  // console.log('getCollectionWhereArrayContains success:', formatCollection(response))
  return formatCollection(response)
}

export const addDocument = async(fsCollection, data) => {
  const docRef = await addDoc(collection(db, fsCollection), data)
  // console.log('addDocument success:', docRef.id, docRef)
  return docRef.id
}

export const getDocument = async (fsDoc) => {
  // console.log('getDocument', fsDoc)
  const {collection, docId} = parseDoc(fsDoc)
  const docRef = doc(db, collection, docId)
  const docSnap = await getDoc(docRef)
  if(docSnap.exists()) {
    // console.log('getDocument success', formatDoc(docSnap))
    return formatDoc(docSnap)
  } else {
    console.error('getDocument error')
    return {
      code: 'Error: getDocument',
      message: 'No such document'
    }
  }
}

export const subscribeToCollectionUpdates = async (fsCollection, onUpdateFn) => {
  return onSnapshot(collection(db, fsCollection), (snapshot) => {
    onUpdateFn(snapshot)
  })
}

export const subscribeToDocUpdates = async (fsDoc, onUpdateFn) => {
  const {collection, docId} = parseDoc(fsDoc)
  return onSnapshot(doc(db, collection, docId), (docSnap) => {
    const document = formatDoc(docSnap)
    // console.log("onDocumentChange", fsDoc, document, onUpdateFn)
    if(onUpdateFn) onUpdateFn(document)
  })
}


export const setDocument = async (fsDoc, docId, data) => {
  // console.log('setDoc:', {fsDoc, docId, data})
  const isMapRoot = fsDoc === 'maps'
  const dataMerged = isMapRoot ? {...{}, ...data, ...{updated: new Date().toISOString()}} : data

  const cityRef = doc(db, fsDoc, docId)
  await setDoc(cityRef, dataMerged, { merge: true })
  // console.log('setDocument success:', docId)
  return true
}

export const deleteDocument = async (fsDoc, docId) => {
  await deleteDoc(doc(db, fsDoc, docId))
  // console.log('deleteDoc success', docId)
  return true
}

export const getField = async (path, field) => {
  // console.log('getField:', {path, field})
  const document = await getDocument(path)
  if(document) {
    // console.log('getField success', document[field])
    return document[field]
  } else {
    console.error('Error: getField', path, field)
    return {
      code: 'Error: getField',
      message: 'No such document'
    }
  }
}

export const incrementFieldNumber = async (path, docId, key, incrementBy) => {
  // console.log('incrementFieldNumber:', {path, docId, key, incrementBy})
  const docRef = doc(db, path, docId)
  await updateDoc(docRef, {
    [key]: increment(incrementBy)
  })
  // console.log('incrementFieldNumber success:', docId)
  return true
}

export const modifyFieldArray = async (path, docId, key, arrayValue, addRemoveBoolean) => {
  // console.log('modifyFieldArray:', {path, docId, key, arrayValue})
  const docRef = doc(db, path, docId)
  await updateDoc(docRef, {
    [key]: addRemoveBoolean ? arrayUnion(arrayValue) : arrayRemove(arrayValue)
  })
  // console.log('modifyFieldArray success:', docId)
  return true
}

export const deleteField = async (path, docId, key) => {
  // console.log('deleteField:', {path, docId, fieldKey})
  const docRef = doc(db, path, docId)
  const docUpdated = await updateDoc(docRef, {
    [key]: db.FieldValue.delete()
  })
  if(docUpdated) {
    // console.log('deleteField success:', docId, docUpdated)
    return true
  } else {
    console.error('Error: deleteField', path, docId, key)
    return false
  }
}

export const deleteDocAndRef = async(fsDoc, fsFieldOrder, currentOrder) => {
  // console.log('deleteDocAndRef:', {fsDoc, fsFieldOrder})
  const {collection, doc, field} = parseField(fsFieldOrder)
  const {docId} = parseDoc(fsDoc)
  const index = currentOrder.findIndex(item => item === docId)
  let newOrder = [...currentOrder]
  newOrder.splice(index, 1)
  const responseOrder = await setDocument(collection, doc, {[field]: newOrder})
  if(responseOrder) {
    // console.log('deleteDocAndRef success', responseOrder)
    const {collection, docId} = parseDoc(fsDoc)
    return await deleteDocument(collection, docId)
  }
}
