export const LIB = Object.freeze({
  account: "Konto",
  already_have_account: "Har du allerede en konto?",
  cancel: "Annuler",
  change_your_email: "Skift din email",
  cannot_be_changed: "kan ikke ændres",
  delete: "Slet",
  delete_account: "Slet konto",
  edit: "Rediger",
  enter_a_password: "Indtast et password",
  enter_current_password: "Indtast dit nuværende password",
  enter_username: "Indtast dit navn",
  enter_your_email: "Indtast din email",
  error_change_email_or_password: "Indtast venligst ny email eller password",
  error_username: "Mindst 3 karakterer, kun A-Z og 0-9",
  error_username_unique: "Brugernavn er desværre optaget",
  error_email: "Indtast venligst email",
  error_enter_password: "Indtast venligst dit password",
  error_full_name: "udfyld venligst dit navn",
  error_password: "Skal indeholde mindst et tal, et stort bogstav og et lille bogstav",
  forgot_password: "Glemt password?",
  go_to_homepage: "Gå til forsiden",
  hide: "Skjul",
  loading: "Henter",
  log_in: "Log ind",
  my_account: "Min profil",
  name: "Navn",
  no_account_yet: "Har du ikke en konto?",
  page_not_found: "Siden kunne ikke findes",
  placeholder_username: "Hans Hansen",
  placeholder_your_email: "din@email.dk",
  reset_password: "Nulstil password",
  save: "Gem",
  show: "Vis",
  sign_out: "Log ud",
  sign_up: "Opret bruger",
  signed_in: "Bruger",
  success_email_updated: "Email opdateret",
  success_password_updated: "Password opdateret",
  terms_and_conditions: "Terms & conditions",
  update: "Opdatering tilgængelig",
  username: "Brugernavn",
  welcome: "Velkommen",
})
