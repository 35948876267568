import React from 'react'
import styles from './TermsConditions.module.css'

export default function TermsConditions() {
  return (
    <section className={styles.TermsConditions}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-5">
            <h1>Terms & Conditions</h1>
          </div>
          <div className="col-xs-12 col-sm-6 col-sm-offset-1">
            <h3>Newsletter Terms and Conditions</h3>
            <p>By subscribing to our newsletter you agree to receive emails from us. The aim of our newletter service is
              to keep you updated about new releases or information about music, concerts or band information.</p>
            <h3>Limited Liability</h3>
            <p>We reserve the right to modify or discontinue the newsletter, at any time with or without notice to you.
              We will not be liable to you or any third party should we exercise such right. Any new features that
              augment or enhance the then-current services on this site shall also be subject to these Terms &
              Conditions.</p>
            <p>We reserve the right to unsubscribe users / visitors from our newsletter service, without notice. We will
              do so with any subscriber we deem registered with fake data.</p>
            {/*
                <h3>Double opt-in</h2>
                <p>We require all subscribers to confirm their email address upon registration. You will therefor receive a message with a link you'll have to click in order to confirm the email address and your will to subscribe.</p>
                */}
            <h3>Privacy policy</h3>
            <p>We will not communicate / spread / publish or otherwise give away your e-mail address. You'll be able to
              change your subscription settings or to delete it anytime.</p>
          </div>
        </div>
      </div>
    </section>
  )
}
