import React, {Component} from 'react'
import styles from './Footer.module.css'
import {addDocument, getCollectionWhere} from '../../services/service-firestore'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icontypes'
import {PATTERN_EMAIL} from '../../constants/consts-patterns'
import {routes} from "../../constants/consts-routes";
import {analyticsEvent} from "../../services/service-analytics"

class Footer extends Component {
  constructor(props) {
    super(props)

    this.checkValidity = this.checkValidity.bind(this)
    this.onEmailChange = this.onEmailChange.bind(this)
    this.onTermsChange = this.onTermsChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onSuccess = this.onSuccess.bind(this)

    this.state = {
      valid: false,
      terms: false,
      email: '',
      submitted: false
    }
  }

  checkValidity() {
    const isValid = this.form.checkValidity()
    // console.log('isValid', isValid)
    this.setState({
      valid: isValid
    })
  }

  onEmailChange(event) {
    const {value} = event.target
    this.setState({
      email: value
    }, this.checkValidity)
  }

  onTermsChange() {
    this.setState(prevState => {
      return {
        terms: !prevState.terms
      }
    }, this.checkValidity)
  }

  async onSubmit(event) {
    event.preventDefault()
    const {valid, email} = this.state

    if(!valid || !email) return

    const responseExists = await getCollectionWhere('profiles', 'email', email.toLowerCase())
    const userNameExists = responseExists && responseExists.length > 0
    console.log('userNameExists', userNameExists)
    if(userNameExists) {
      this.onSuccess()
      return
    }

    const response = await addDocument('profiles', {
      email: email.toLowerCase()
    })
    if(response) {
      this.onSuccess()
    }
  }

  onSuccess() {
    this.setState({submitted: true})

    setTimeout(() => {
      this.setState({
        valid: false,
        terms: false,
        email: '',
        submitted: false
      })
    }, 4000)

    analyticsEvent('newsletter', 'signup', 'success')
  }

  render() {
    const {valid, terms, email, submitted} = this.state

    return (
      <footer id="footer" className={styles.Footer}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4">
              <form ref={n => this.form = n}>
                <h2>Sign up for news & concert updates</h2>
                <label htmlFor="email">EMAIL</label>
                <div className="input-group">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    pattern={PATTERN_EMAIL}
                    value={email}
                    placeholder="your@email.com"
                    onChange={this.onEmailChange}
                    required
                    disabled={submitted}
                  />
                </div>

                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={terms}
                    onChange={this.onTermsChange}
                    required={true}
                  />
                  <span className="checklabel">I agree to these <a
                    href={routes.terms_and_conditions.pathname} target="_blank" rel="noreferrer noopener">Terms & conditions</a>.*</span>
                  <span className="checkmark"/>
                </label>

                <button
                  className="button"
                  style={{marginTop: '1rem'}}
                  onClick={this.onSubmit}
                  disabled={!valid || submitted}
                >
                  <span style={{opacity: submitted ? 0 : 1}}>Yes, please!</span>
                  <span className={styles.iconCheck} style={{opacity: submitted ? 1 : 0}}>
                    <Svg id={ICONS.checkmarkLarge}/>
                  </span>
                </button>

              </form>
            </div>
          </div>
        </div>

        <div className={styles.some}>
          <div className="container">
            <div className="row">
              <div className="col-xs-4 col-md-2">
                <a href="https://www.instagram.com/jajamusic_dk/" target="_blank" rel="noreferrer noopener">INSTAGRAM</a>
              </div>
              <div className="col-xs-4 col-md-2">
                <a href="https://www.facebook.com/jajamusic.dk" target="_blank" rel="noreferrer noopener">FACEBOOK</a>
              </div>
              <div className="col-xs-4 col-md-2">
                <a href="https://www.youtube.com/channel/UC9OGfWtjYkLCrwRGZbkTPtQ" target="_blank" rel="noreferrer noopener">YOUTUBE</a>
              </div>
              <div className="col-xs-4 col-md-2">
                <a href="https://open.spotify.com/artist/6fhXwAOJoiRxwZGYUq2hpZ?si=kjxRqo5FQFmdBedrDqm2Ow" target="_blank" rel="noreferrer noopener">SPOTIFY</a>
              </div>
              <div className="col-xs-4 col-md-2">
                <a href="https://music.apple.com/us/artist/ja-ja/1619127671" target="_blank" rel="noreferrer noopener">APPLE MUSIC</a>
              </div>

            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
