import React, {useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import styles from './Forgot.module.css'
import {routes} from '../../constants/consts-routes'
import Svg from '../../components/Svg/Svg'
import ICONS from '../../constants/consts-icontypes'
import {resetPassword} from '../../services/service-auth'
import {LIB} from '../../constants/consts-dictionary'
import {PATTERN_EMAIL} from '../../constants/consts-patterns'

export default function Forgot() {
  const form = useRef()
  const navigate = useNavigate()
  const [error, setError] = useState(null)

  async function onSubmit(event) {
    const valid = form.current.checkValidity()

    if(valid) {
      event.preventDefault()
      const email = form.current.elements.namedItem('email').value

      const response = await resetPassword(email)
      console.log('response', response)

      if(response && response.code) {
        setError(response.message)
      } else {
        navigate(routes.login.pathname)
      }
    }
  }

  return (
    <section className={styles.Forgot}>
      <div className="container">
        <div className="row center-xs">
          <div className="col-xs-12 col-sm-6 col-lg-5 col-xl-4 col-xxl-3">

            <form ref={form}>
              <div className="input-wrapper">
                <label htmlFor="email">{LIB.enter_your_email}</label>
                <div className="input">
                  <input
                    id="email"
                    type="email"
                    placeholder={LIB.placeholder_your_email}
                    pattern={PATTERN_EMAIL}
                    title={LIB.error_email}
                    required
                  />
                </div>
              </div>

              {error &&
                <div className="error-message">{error}</div>
              }

              <button
                type="submit"
                onClick={onSubmit}
              >
                {LIB.reset_password}
              </button>
            </form>

            <small className={styles.signupWrapper}>
              <Link to={routes.login.pathname}>
                <span><strong>{LIB.log_in}</strong></span>
                <Svg id={ICONS.arrowRightSmall}/>
              </Link>
            </small>
          </div>
        </div>
      </div>
    </section>
  )
}
