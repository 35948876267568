export const ENDPOINT_VERSION = `/version.json`

const headersDefault = Object.freeze({
  'Content-Type': 'application/json'
})

export const getVersion = () => {
  const options = {
    method: 'GET',
    headers: {...{}, ...headersDefault}
  }

  const timestamp = new Date().getTime().toString()

  return fetch(`${ENDPOINT_VERSION}?t=${timestamp}`, options)
    .then(res => res)
    .then(async res => {
      if(res.status >= 400 && res.status < 600) {
        new Error('ERROR loading file: /version.json')
      }
      return res.json()
    })
    .catch(error => console.log(error))
}
