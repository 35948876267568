import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {AppStateProvider} from '../StateContext'
import {observeUser} from '../services/service-auth'
import {initFirebase} from '../services/service-firebase'
import Views from './Views/Views'
import {routes} from '../constants/consts-routes'
import {isDevEnvironment} from '../services/service-utils'
import {getVersion} from '../services/service-version-control'

export default function App() {
  // const [version, setVersion] = useState(null)
  const [user, setUser] = useState(undefined)
  const navigate = useNavigate()

  if(window.APP_VERSION) console.log('APP_VERSION', window.APP_VERSION)

  if(!isDevEnvironment) runVersionControl()

  initFirebase()
  observeUser(onUserStateChange)

  async function runVersionControl() {
    const response = await getVersion()
    if(response) {
      if(response.version !== window.APP_VERSION) {
        console.log('VERSION CACHE ERROR')
        navigate(routes.update.pathname)
      } else {
        setTimeout(runVersionControl, 10000)
      }
    } else {
      setTimeout(runVersionControl, 10000)
    }
  }

  function onUserStateChange(user) {
    // console.log('onUserStateChange', Boolean(user))
    setUser(user)
  }

  const state = {
    user: user
  }

  return (
    <AppStateProvider value={state}>
      <Views/>
    </AppStateProvider>
  )
}
